import React from 'react';
import { useLocation } from 'react-router-dom';

import * as Yup from 'yup';

import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import { emailValidator, phoneValidator, simpleStringValidator } from '@common/react/utils/validationHelpers';
import PhoneInput from '@common/react/components/Forms/PhoneInput/PhoneInput';
import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';
import { useMobileView } from '@common/react/hooks/useMobileView';

import LogoLoader from '@commonTuna/react/components/UI/LogoLoader/LogoLoader';
import { Device } from '@commonTuna/react/objects/Device';

interface FormValues {
	id: number;
	name: string;
	email: string;
	text: string;
	phone: string;
	path: string;
	device: Device;
}

interface Props {
	className?: string;
	idPrefix?: string;
	hideTitle?: boolean;
	title?: string;
}

const initialValues: FormValues = {
	id: -1,
	name: '',
	email: '',
	text: '',
	phone: '',
	path: '',
	device: Device.Desktop,
};

const validationSchema = Yup.object().shape({
	name: simpleStringValidator,
	phone: phoneValidator,
	email: emailValidator,
	// text: simpleStringValidator
});

const successMessage = 'Thank you! Your inquiry has been successfully sent. Our manager will contact you as soon as possible';

const NewConcatForm: React.FC<Props> = ({
	className = '', idPrefix = '', hideTitle, title = 'Book Appointment',
}) => {
	const [visible, setVisible] = React.useState(true);

	const isMobile = useMobileView();
	const location = useLocation();

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		form,
		name,
	) => {
		const { value } = e.currentTarget;
		form.setFieldValue(name, value);
	};

	return (
		<div className={`inquiry-wrapper clearfix ${!visible ? 'form-sending' : ''} ${className}`}>
			<ItemProvider
				id={-1}
				item={initialValues}
				withoutAdd
				type="inquiry"
				readonly={false}
				validationSchema={validationSchema}
				transformAfterSave={() => initialValues}
				clearForSubmit={(values) => ({
					name: values.name,
					email: values.email,
					text: values.text,
					phone: values.phone,
					mobile: isMobile,
					path: location.pathname,
					device: isMobile ? Device.Mobile : Device.Desktop,
				})}
				onRequestError={() => setTimeout(() => setVisible(true), 1000)}
				transformErrorMessage={() => 'Something went wrong'}
			>
				{({ state: { error, loading, message } }) => {
					return <>
						{(loading || error) && <div className="loader">
							{loading && <LogoLoader />}
							{!loading && error && <div className="error">
								<i className="fa fa-exclamation" />
								{' '}
								{error}
							</div>}
						</div>}
						{message
								&& <div className="success-container">
									<div className="icon">
										<i className="fa fa-check" />
									</div>
									<div className="success">{message}</div>
								</div>
						}
						<div className="inquiry">
							{title && <h2 className={`col-sm-12 ${hideTitle ? 'hide-mobile' : ''}`}>{title}</h2>}
							<ItemEditor<FormValues>
								beforeSubmit={(values, actions, submit) => {
									setVisible(false);
									submit();
								}}
								showMessageDuration={7000}
								afterSubmit={() => {
									setTimeout(() => setVisible(true), 5000);
								}}
								showMessages={false}
								successMessage={successMessage}
								edit={(formikBag, _) => {
									return (<>
										<FormikField
											title="NAME"
											fieldName="name"
											inputId={`${idPrefix}name`}
											containerClassName="col-sm-12"
											inputProps={{
												required: true,
												id: `${idPrefix}name`,
											}}
										/>
										<FormikField
											title="EMAIL"
											fieldName="email"
											inputId={`${idPrefix}email`}
											containerClassName="col-sm-12"
											inputProps={{
												required: true,
												id: `${idPrefix}email`,
											}}
										/>
										<FormikField
											title="PHONE"
											fieldName="phone"
											inputId={`${idPrefix}phone`}
											containerClassName="col-sm-12"
											render={({ field, form }) => (<>
												<PhoneInput
													id={`${idPrefix}phone`}
													value={field.value}
													className="form-control"
													onChange={(e: any) => handleChange(e, form, field.name)}
												/>
											</>
											)}
										/>
										<FormikField
											title="MESSAGE"
											fieldName="text"
											inputId={`${idPrefix}text`}
											containerClassName="col-sm-12"
											inputProps={{
												id: `${idPrefix}text`,
											}}
											render={({ field }) =>
												<textarea
													className="form-control"
													{...field}
													id={`${idPrefix}text`}
													value={field.value === null ? '' : field.value}
												/>}
										/>
										<div className="col-sm-12">
											<button
												type="submit"
												className="btn submit-button"
												disabled={loading}
											>
												SUBMIT
											</button>
										</div>
									</>
									);
								}}
							/>
						</div>
						<div className="col-sm-12 messages">
							{error && <div className="error">
								<i className="fa fa-exclamation" />
								{' '}
								{error}
							</div>}
						</div>
					</>;
				}}
			</ItemProvider>
		</div>
	);
};

export default NewConcatForm;
